<template>
  <div>
    <rxNavBar v-if="androidFlg == false" androidOrIOSFlag="true" title="试岗任务详情"></rxNavBar>
    <rxNavBar v-else title="试岗任务详情"></rxNavBar>
    <!--    <rxNavBar title="试岗任务详情" androidOrIOSFlag="true"></rxNavBar>-->
    <van-tabs v-model="active"
              line-height="5"
              line-width="20"
              offset-top="1.33rem"
              sticky
              title-active-color="rgb(255,81,45)" @scroll="scroll"
    >
      <van-tab title="详情">
        <div id="planInfoPreview"></div>
        <div class="learningAttachmentsTitle">学习附件</div>
        <div class="learningAttachments">
          <van-uploader v-model="fileList"
                        accept=""
                        disabled
                        image-fit="contain"
                        multiple
                        upload-icon="plus"
                        @click-preview="clickFile"
          >
          </van-uploader>
        </div>
      </van-tab>
      <van-tab title="审批">
        <div class="approve">
          <div class="time_line">
            <div class="item">
              <div class="left">
                <div :class="{line:true ,line_top:true,first_top:true}"></div>
                <div class="point"></div>
                <div v-if="approvalHistroyList.length!==0" class="line line_bottom"></div>
              </div>
              <div class="right">
                <div class="approve_panel">
                  <p class="total_color">
                    申请
                    <!--                    <span class="floatR small_font">{{approvalHistroyInfo.applicationtime}}</span>-->
                  </p>
                  <p>
                    {{ approvalHistroyInfo.staffName }}
                  </p>
                </div>
              </div>
            </div>
            <div v-for="(item,index) in approvalHistroyList" v-if="approvalHistroyList.length!==0" :key="index"
                 class="item">
              <div class="left">
                <div :class="{line:true ,line_top:true,first_top:false}"></div>
                <div class="point"></div>
                <div v-if="index != approvalHistroyList.length - 1" class="line line_bottom"></div>
              </div>
              <div class="right">
                <div class="approve_panel">
                  <p class="total_color">
                    {{ item.approvalResultStr }}
                    <span style="display: inline-block;margin-left: 0.2rem">{{ item.approvalTypeStr }}</span>
                    <span class="floatR small_font">{{ item.addTime }}</span>
                  </p>
                  <p>
                    {{ item.staffName }}
                    <span style="display: inline-block;margin-left: 0.2rem">{{ item.remarks }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="预约考试">
        <div class="approve">
          <div class="time_line">
            <div v-for="(item,index) in quizAppointmentList" :key="index" class="item">
              <div class="left">
                <div :class="{line:true ,line_top:true,first_top:index==0}"></div>
                <div class="point"></div>
                <div v-if="index != quizAppointmentList.length - 1" class="line line_bottom"></div>
              </div>
              <div class="right">
                <div class="approve_panel">
                  <p class="total_color">
                    {{ item.statusStr }}
                    <span class="floatR small_font">{{ item.appointmentTime }}</span>
                  </p>
                  <p>
                    <span v-if="item.status=='1' || item.status=='4'"
                          style="display: inline-block;">{{ item.reason }}</span>
                    <span v-if="item.status=='2'||item.status=='3'||item.status=='5'"
                          style="display: block">{{item.score?'成绩上传时间：':'审批时间：'}}{{ item.actualTime }}</span>
                    <span v-if="(item.status=='5'||item.status=='2'||item.status=='3')&&item.score" style="display: inline-block;">分数：{{ item.score }}</span>
                    <span v-if="item.orderPlacedTime">开单时间：{{item.orderPlacedTime}}</span>

                  </p>
                  <p v-if="item.picList!=undefined && item.picList.length!==0">
                    <span v-for="(picItem,picIndex) in item.picList" :key="picItem.url">
                      <van-image :height="60" :src="picItem.path" :width="60" class="head_sculpture" fit="contain"
                                 radius="5px" @click="openImg(item.picList,picIndex)"/>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
import {
  Button,
  Divider,
  DropdownItem,
  DropdownMenu, Image as VanImage, ImagePreview,
  List,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Search, Tab, Tabs,
  uploader
} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {checkAndroid, checkIOS, getStaffId} from "../../../libs/rongxunUtil";
import {trialplanrecord, queryApprovalHistory, queryQuizAppointment} from "../../../getData/getData";

export default {
  name: "probationAssignmentDetail",
  components: {
    [Button.name]: Button,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Divider.name]: Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]: PullRefresh,
    [uploader.name]: uploader,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      androidFlg: '',
      active: 0,
      offsetTop: '',
      fileList: [],
      approvalHistroyList: [],
      approvalHistroyInfo: {},
      quizAppointmentList: [],
    }
  },
  mounted() {
    this.checkPhoneorMobel()
    this.initProbationPlanDetail()
    this.queryApprovalHistory()
    this.queryQuizAppointment()
  },
  methods: {
    checkPhoneorMobel() {
      if (checkAndroid()) {
        this.androidFlg = true
      } else if (checkIOS()) {
        this.androidFlg = false
      }
    },
    initProbationPlanDetail() {
      let that = this
      let initData = {}
      initData.user_id = getStaffId()
      initData.mainTrialplanRecord_id = this.$route.query.id
      this.trialplanrecordList = []
      trialplanrecord(initData).then(function (response) {
        that.fileList = []  //附件
        for (let i in response.data.data.fileList) {
          let map = {}
          map.url = response.data.data.fileList[i].path
          map.id = response.data.data.fileList[i].id
          map.file = new File([], response.data.data.fileList[i].name, {})
          map.deletable = false
          that.fileList.push(map)
        }

        let content = response.data.data.probationPlan.content  //主文本
        let planDetailList = response.data.data.trialplanrecordList  //替换码
        for (let i in planDetailList) {
          let charToReplace = planDetailList[i].substitution; // 要被替换的字符
          let charToReplaceStr = (planDetailList[i].substitution).substring(2, planDetailList[i].substitution.length - 2); // 要被替换的绑定键
          let ownContent = planDetailList[i].content != undefined && planDetailList[i].content !== '' ? planDetailList[i].content : '';  //作答的填写
          let replacementHTML = `<span class="divSpanBox">【${ownContent}】</span>`
          content = content.replace(charToReplace, replacementHTML)
        }
        let newParagraph = document.createElement("p")  //创建内容元素节点
        newParagraph.insertAdjacentHTML("beforeend", content);  //放HTML  //在元素里最后一个节点后 插入一个html
        let container = document.getElementById("planInfoPreview");
        container.appendChild(newParagraph);

        //赋值class，写样式
        for (let j in document.getElementsByTagName("p")) {
          if (typeof document.getElementsByTagName("p")[j] == 'object') {
            document.getElementsByTagName("p")[j].className = 'divBox'
          }
        }
      })

    },
    queryApprovalHistory() {
      let that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.mainTrialplanRecord_id = this.$route.query.id
      queryApprovalHistory(initData).then(function (response) {
        that.approvalHistroyList = response.data.data.data ? response.data.data.data : []
        that.approvalHistroyInfo = response.data.data

      })
    },
    queryQuizAppointment() {
      let that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.mainTrialplanRecord_id = this.$route.query.id
      queryQuizAppointment(initData).then(function (response) {
        that.quizAppointmentList = response.data.data.data
      })
    },
    //点击预览图片
    openImg(picList, startIndex) {
      let arr = []
      for (let i in picList) {
        arr.push(picList[i].path)
      }
      ImagePreview({
        images: arr,
        startPosition: startIndex,
        onClose() {
        },
      })
    },
    //点击查看文件
    clickFile(file) {
      let extensionThr = file.url.substr(-3)
      let extensionFour = file.url.substr(-4)
      if (extensionThr === 'txt' || extensionThr === 'xls' || extensionThr === 'doc' || extensionFour === 'xlsx' || extensionFour === 'docx') {
        this.$router.push({
          name: 'electronicContract',
          query: {
            type: 'ssqian',
            electronicContractUrl: file.url,
            title: file.file.name,
          }
        });
      } else if (extensionThr === 'pdf') {
        this.$router.push({
          name: 'electronicContract',
          query: {
            type: 'fxqian',  //pdf的标识
            electronicContractUrl: file.url,
            title: file.file.name
          }
        });
      } else if (extensionThr === 'png' || extensionThr === 'jpg') {
        return;
      } else {
        // responseUtil.alertMsg(this,'暂不支持打开此类文件')
        // return
      }
    },
    // 滚动时获取nav高度并赋值
    scroll() {
      if (this.$refs.navBar) {
        this.offsetTop = this.$refs.navBar.clientHeight
      }

    },
  }

}
</script>

<style lang="less" scoped>
* {
  margin: 0px;
  padding: 0px;
}

#planInfoPreview {
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.2rem;
  background-color: white;
  border-radius: 0.5rem;
}

.learningAttachmentsTitle {
  padding: 5px 0px;
  color: rgb(244, 99, 76);
  margin: 25px 0.3rem 0rem 0.4rem;
  font-weight: 700;
  font-size: 12px;
}

.learningAttachments {
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.4rem;
  background-color: white;
  border-radius: 0.5rem;
}

::v-deep .divBox {
  font-size: 16px;
}

::v-deep .divSpanBox {
  color: blue;
  text-decoration: underline;
}

::v-deep img {
  width: 100%;
  height: auto;
}

// 时间线
.approve {
  margin-top: 20px;
  font-size: 12px;

  .time_line {
    .item {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      .left {
        width: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        /*margin-right: 5px;*/

        .line {
          width: 1px;
          background-color: rgb(255, 75, 39);
          position: absolute;

          &.line_top {
            top: 0px;
            height: ceil(50%-8px);

            &.first_top {
              background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Safari 5.1 - 6.0 */
              background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Opera 11.1 - 12.0 */
              background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Firefox 3.6 - 15 */
              background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
            }
          }

          &.line_bottom {
            bottom: 0;
            height: ceil(50%+10px);
          }
        }

        .point {
          position: relative;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          border: 1px solid rgb(255, 75, 39);
          transform: translateY(-50%);
          z-index: 99;
          background-color: rgb(255, 255, 255);

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgb(255, 75, 39);
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }


      }

      .right {
        width: 100%;
        padding-bottom: 20px;

        .approve_panel {
          margin: 0 15px;
          border-radius: 8px;
          background-color: rgb(255, 255, 255);
          padding: 10px;

          p {
            padding-top: 10px;
            font-weight: bolder;

            &:last-of-type {
              padding-bottom: 10px;
            }

            span {
              font-weight: normal;
              color: rgb(82, 82, 82);
            }

            .small_font {
              font-size: 12px !important;
            }

            .floatR {
              float: right !important;
            }

            .head_sculpture {
              margin-right: 0.1rem;
            }
          }
        }
      }
    }

  }
}
</style>