import { render, staticRenderFns } from "./probationAssignmentDetail.vue?vue&type=template&id=29230510&scoped=true&"
import script from "./probationAssignmentDetail.vue?vue&type=script&lang=js&"
export * from "./probationAssignmentDetail.vue?vue&type=script&lang=js&"
import style0 from "./probationAssignmentDetail.vue?vue&type=style&index=0&id=29230510&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29230510",
  null
  
)

export default component.exports